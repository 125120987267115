import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Stratégie Produit',
    'Agile & Scrum',
    'Gestion des Parties Prenantes',
    'Gestion du Cycle de Vie du Produit',
    'Prise de Décision Basée sur les Données',
    'Analyse de Marché',
    'Partenariats Numériques',
    'Conception UX/UI',
    'Figma',
    'Canva',
    'Jira',
    'Trello',
    'Notion',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">À Propos de Moi</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Bonjour ! Je m'appelle Zineb et je suis passionnée par la gestion de produit et la réussite des entreprises grâce à des initiatives stratégiques. Mon parcours a commencé par un intérêt marqué pour comprendre comment les produits peuvent transformer les entreprises et améliorer les expériences clients.
            </p>

            <p>
              Au fil des années, j'ai eu le privilège de travailler sur divers projets réussis, y compris le lancement d'une application mobile B2B qui a amélioré l'efficacité des commandes des clients de 8 %, et le développement d'un catalogue de produits mondial qui a facilité la communication avec les clients et les partenaires.
            </p>

            <p>
              Mon expérience inclut la collaboration avec des équipes pluridisciplinaires pour livrer des produits à fort impact, la gestion des budgets, et l'optimisation des stratégies marketing pour atteindre une croissance durable.
            </p>

            <p>
              En tant que Chef de Produit et Product Owner Certifiée, je me concentre sur la réalisation de résultats exceptionnels, que ce soit par l'optimisation des stratégies produits ou le développement de partenariats numériques pour étendre la portée du marché.
            </p>

            <p>Voici quelques compétences sur lesquelles je me concentre actuellement :</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpeg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Photo de Profil"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
