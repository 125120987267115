import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { usePrefersReducedMotion } from '../../../hooks';
import { navDelay, loaderDelay } from '../../../utils';
import { StyledHeroSection } from './styles';
import React, { useState, useEffect } from 'react';
import { email } from '../../../config';

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, [prefersReducedMotion]);

  const one = <h1>Bonjour, je m'appelle</h1>;
  const two = <h2 className="big-heading">Zineb Dounit</h2>;
  const three = <h3 className="big-heading">Je suis Product Manager.</h3>;
  const four = (
    <p>
      Je suis passionnée par le succès des produits et par la réalisation de résultats exceptionnels grâce à une gestion stratégique des produits.
      En tant que Product Owner Certifiée (PSPO 1), je me spécialise dans le lancement de produits réussis, l'optimisation des stratégies produits, 
      et la création de partenariats solides. Mon travail se concentre sur l'amélioration de la croissance des entreprises et la satisfaction des clients.
      Je suis toujours à la recherche de nouvelles opportunités pour innover et créer des solutions impactantes dans le domaine de la gestion de produit.
    </p>
  );
  const five = (
    <a href={`mailto:${email}`} className="email-link">
      Contacter
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
